import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import BHBreadcrumbs from './theme/components/bh-breadcrumbs'
import BHCrossSell from './theme/components/bh-crosssell'
import BHRelated from './theme/components/bh-related'
import BHSuggestedStoreRow from './theme/components/bh-suggested-store-row'
import BHTileBase from './theme/components/bh-tile-base'
import BHTileTooltip from './theme/components/bh-tile-tooltip'
import BHUpsells from './theme/components/bh-upsells'
import Button from './theme/components/button'
import CssBaseline from './theme/components/css-baseline'
import BHDropdown from './theme/components/form/dropdown'
import Link from './theme/components/link'
import Table from './theme/components/table'
import Tooltip from './theme/components/tooltip'
import { fonts } from './theme/fonts'

const htmlFontSize = 0.75
const fontSize = 0.75

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.manrope.fontFamily
const fontFamilyAlternative = fonts.times.fontFamily

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#FFFFFF',
			red: '#D00A26',
		},
		black: {
			main: '#000000',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		grey: {
			main: '#747474',
			input: '#676767',
			border: '#EAEAEA',
			label: '#AFAFAF',
			light: '#959595',
			breadcrumbs: '#9F9F9F',
			vat: '#A0A0A0',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: '#ECECEC',
			color: '#000000',
			copyright: '#777777',
			service: '#F2F2F2',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#F6F6F6',
		},
		button: {
			disabled: '#A8A8A8',
		},
	},
	typography: {
		color: '#000000',
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,
		fontWeightExtraBold: 800,
		lineHeight: '16px',
		fontWeight: 400,
		h1: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: 'normal',
			letterSpacing: 0,
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h3: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18),
			},
		},
		h4: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(22),
			},
		},
		h5: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
			},
		},
		h6: {
			...fonts.manrope,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 'normal',
			letterSpacing: '0px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
			},
		},
		body1: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 'normal',
			letterSpacing: '0',
			fontWeight: 300,
		},
		body2: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1,
			letterSpacing: '0',
		},
		subtitle1: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.4,
			letterSpacing: '0',
			fontWeight: 300,
		},
		subtitle2: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1,
			letterSpacing: '0',
			fontWeight: 600,
		},
		subheadline1: {
			...fonts.manrope,
		},
		subheadline2: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1,
			letterSpacing: '0',
			fontWeight: 600,
		},
		button: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		caption: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '0',
		},
		overline: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.2,
			letterSpacing: '0',
			textTransform: 'uppercase',
		},
		headlineBiggest: {
			...fonts.manrope,
			fontWeight: 300,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: '40px',
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		headlineBig: {
			...fonts.manrope,
			fontWeight: 300,
			fontSize: muiTheme.typography.pxToRem(32),
			lineHeight: '40px',
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(32),
			},
		},
		headlineMedium: {
			...fonts.manrope,
			fontWeight: 300,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: '32px',
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
		},
		headlineTextBanner: {
			...fonts.manrope,
			fontWeight: 300,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: '32px',
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(32),
				lineHeight: '40px',
			},
		},
		productTile: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1,
			letterSpacing: 'unset',
			fontWeight: 400,
		},
		footerTitle: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: 1,
			letterSpacing: 'unset',
			fontWeight: 600,
			[defaultTheme.breakpoints.up('md')]: {
				fontSize: muiTheme.typography.pxToRem(14),
				fontWeight: 800,
			},
		},
		linkPrimary: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			fontWeight: 600,
			lineHeight: 'normal',
			letterSpacing: '1.2px',
			textDecoration: 'none',
			borderBottom: '1px solid',
			borderBottomColor: '#000000',
			paddingBottom: '4px',
			textTransform: 'uppercase',
			color: '#000000',
		},
		linkSecondary: {
			...fonts.manrope,
			fontSize: muiTheme.typography.pxToRem(12),
			fontWeight: 600,
			lineHeight: 'normal',
			letterSpacing: '1.2px',
			textDecoration: 'none',
			borderBottom: '1px solid',
			borderBottomColor: '#ffffff',
			paddingBottom: '4px',
			textTransform: 'uppercase',
			color: '#ffffff',
		},
	},

	scrollbar: `
		overflow-y: auto;
		scrollbar-width: auto;
		scrollbar-color: #777777 #F7F7F7; // thumb, track

		::-webkit-scrollbar {
			width: 7px;
			height: 5px;
		}

		//track
		::-webkit-scrollbar-track {
			background: #F7F7F7; // grey.lighter
			border-radius: 3.5px;
		}

		//handle
		::-webkit-scrollbar-thumb {
			background: #777777; // grey.main
			border-radius: 3.5px;
		}

		//handle on hover
		::-webkit-scrollbar-thumb:hover {
			background: #373737; // grey.dark
		}
	`,

	components: {
		...BHBreadcrumbs,
		...BHCrossSell,
		...BHDropdown,
		...BHRelated,
		...BHSuggestedStoreRow,
		...BHTileBase,
		...BHTileTooltip,
		...BHUpsells,
		...Button,
		...CssBaseline,
		...Link,
		...Table,
		...Tooltip,
	},
})

export default theme
